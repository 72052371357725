import { Action, configureStore, getDefaultMiddleware, ThunkAction } from '@reduxjs/toolkit';
import alertsReducer from 'shared/reducers/alertsSlice';
import productReducer from 'shared/reducers/productSlice';
import autoOrderReducer from 'shared/reducers/autoOrdersSlice';
import authenticationReducer from 'shared/reducers/authenticationSlice';
import callbacksReducer from 'shared/reducers/callbacksSlice';
import dashboardReducer from 'shared/reducers/dashboardSlice';
import deviceReducer from 'shared/reducers/devicesSlice';
import graphReducer from 'shared/reducers/graphsSlice';
import groupReducer from 'shared/reducers/groupSlice';
import layoutReducer from 'shared/reducers/layoutSlice';
import notifierReducer from 'shared/reducers/notifierSlice';
import rulesReducer from 'shared/reducers/rulesSlice';
import settingsReducer from 'shared/reducers/settingsSlice';
import usersReducer from 'shared/reducers/usersSlice';
import workspaceReducer from 'shared/reducers/workspaceSlice';
import axiosUtils from 'shared/utils/axios-utils';
import loggerMiddleware from './logger-middleware';

const configureAppStore = (preloadedState?: any) => {
  const defaultMiddleware = [loggerMiddleware];

  const store = configureStore({
    reducer: {
      layout: layoutReducer,
      dashboard: dashboardReducer,
      authentication: authenticationReducer,
      users: usersReducer,
      notifier: notifierReducer,
      workspace: workspaceReducer,
      group: groupReducer,
      autoOrder: autoOrderReducer,
      product: productReducer,
      devices: deviceReducer,
      alerts: alertsReducer,
      graphs: graphReducer,
      rules: rulesReducer,
      callbacks: callbacksReducer,
      settings: settingsReducer
    },
    middleware: [
      ...defaultMiddleware,
      ...getDefaultMiddleware({
        immutableCheck: false, //can be set to false in development mode in order to avaoid warning message because the state is too large
        serializableCheck: false
      })
    ],
    preloadedState
  });

  return store;
};

export const store = configureAppStore();

axiosUtils.setupInterceptors(store);

export type IRootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  IRootState,
  unknown,
  Action<string>
>;
