import { Box, Button, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import { getEnvApiUrl } from 'config/env';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ICalibrateDevice } from 'shared/model/api.model';
import { IDevice } from 'shared/model/device.model';
import { errorNotification, successNotification } from 'shared/reducers/notifierSlice';
import NanolikeDateTimePicker from 'shared/widgets/form/nanolikeDateTimePicker';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { ICalibration } from 'shared/model/calibration.model';

const apiUrl = getEnvApiUrl();
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        width: '20rem'
      }
    }
  })
);

interface ICalibrateDialogProps {
  open: boolean;
  calibration?: ICalibration;
  device: IDevice;
  handleClose: () => void;
  onSuccess?: () => void;
}

const CalibrateDialog = (props: ICalibrateDialogProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  // const updating = useSelector(({ devices }: IRootState) => devices.updating);
  const { device, open, handleClose } = props;
  const [updating, setUpdating] = useState(false);
  const form = useForm<ICalibrateDevice>({
    defaultValues: {
      calibration_level: props?.calibration?.quantity ?? 0,
      calibration_date: moment(props.calibration?.calibration_date) ?? moment()
    }
  });
  const { register, errors } = form;

  const handleConfirm = async (responses: ICalibrateDevice) => {
    const toSend = {
      calibration_level: Number(responses.calibration_level),
      calibration_date: moment(responses.calibration_date)?.toISOString()
    };
    try {
      setUpdating(true);
      props.calibration
        ? await axios.patch(
            `${apiUrl}/internal/calibration-level/${props.calibration.idCalibration}`,
            toSend
          )
        : await axios.post(`${apiUrl}/v1/devices/${props.device.device_id}/calibrate`, toSend);
      dispatch(successNotification(t('calibrate_success')));
      setUpdating(false);
      form.reset();
      if (props.onSuccess) props.onSuccess();
      handleClose();
    } catch (err) {
      setUpdating(false);
      const errorMsg = getRequestErrorMessage(err);
      dispatch(errorNotification(errorMsg));
    }
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
      <FormContext {...form}>
        <form
          className={classes.root}
          onSubmit={form.handleSubmit(handleConfirm)}
          autoComplete="off"
        >
          <DialogTitle>{t('calibrate')}</DialogTitle>
          <DialogContent>
            <Box>
              <TextField
                name="calibration_level"
                type="number"
                label={t('string_workspace_filling_unit', { value: t('level') })}
                InputProps={{ inputProps: { step: 0.1 } }}
                inputRef={register({
                  required: <Trans i18nKey="required_field">Required Field</Trans>,
                  min: {
                    value: 0,
                    message: <Trans i18nKey="should_be_above_zero">Should be greater than 0</Trans>
                  },
                  max: {
                    value: device.capa_max,
                    message: (
                      <Trans
                        i18nKey="should_be_equal_or_less_than"
                        values={{ value: device.capa_max }}
                      >
                        Should be equal or less than
                      </Trans>
                    )
                  }
                })}
                error={errors.calibration_level ? true : false}
                helperText={errors.calibration_level && errors.calibration_level.message}
                disabled={updating}
                fullWidth
                autoFocus
              />
            </Box>
            <Box>
              <NanolikeDateTimePicker
                name="calibration_date"
                value={moment(props.calibration?.calibration_date) ?? moment()}
                label={t('calibration_date')}
                disabled={updating}
                required
                disableFuture
              />
            </Box>
            <Box marginTop={2} marginBottom={1}>
              <Alert severity="info">{t('calibrate_warning')}</Alert>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleClose}
              variant="contained"
              color="default"
              startIcon={<CancelIcon />}
              disabled={updating}
            >
              {t('cancel')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<DoneIcon />}
              autoFocus
              disabled={updating}
            >
              {t('confirm')}
            </Button>
          </DialogActions>
        </form>
      </FormContext>
    </Dialog>
  );
};

export default CalibrateDialog;
