import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { LocalShipping } from '@material-ui/icons';
import { IRootState } from 'config/store';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PrivateComponent from 'shared/auth/privateComponent';
import {
  APP_LOCAL_DATE_FORMAT,
  APP_TIMESTAMP_FORMAT,
  convertDateFromServer,
  formatDate
} from 'shared/utils/date-utils';
// import { getSupportEmail } from 'shared/utils/workspace-utils';
import DataTable from 'shared/widgets/dataTable';
import { newCalibrationsDeliveryTo, newCalibrationsLevelTo } from '.';
import { Edit } from '@material-ui/icons';
import DeliveryDialog from 'modules/devices/actions/deliveryDialog';
import ConfirmDelete from 'shared/widgets/confirmDelete';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { DatePicker } from '@material-ui/pickers';
import { getGroupsDevices, useIsAuthorised } from 'shared/auth/auth-utils';
import { ReactComponent as CalibrateIcon } from 'shared/icons/CalibrateIcon.svg';
import { ICalibration } from 'shared/model/calibration.model';
import CalibrationTypeFilter from './calibrationTypeFilter';
import UserAvatar from 'shared/widgets/userAvatar';
import CalibrateDialog from 'modules/devices/actions/calibrateDialog';
import GroupFilter from 'shared/widgets/groups/groupFilter';
import SearchIcon from '@material-ui/icons/Search';
import UserFilter from 'shared/widgets/users/userFilter';
import { capitalize, InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core';
import { successNotification } from 'shared/reducers/notifierSlice';
import { IDevice } from 'shared/model/device.model';
import isTruthy from 'shared/utils/isTruthy';

const useStyles = makeStyles((theme: Theme) => ({
  searchField: {
    marginTop: 0,
    marginLeft: theme.spacing(2),
    width: 150,
    marginBottom: theme.spacing(1)
  }
}));

const Calibrations = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const allGroups = useSelector(({ group }: IRootState) => group.groups);
  const devices = getGroupsDevices(allGroups);
  const [selectedRows, setSelectedRows] = useState<ICalibration[]>([]);
  const [openDeliveryEditionDialog, setOpenDeliveryEditionDialog] = useState(false);
  const [openLevelEditionDialog, setOpenLevelEditionDialog] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [typeFilter, setTypeFilter] = useState<'delivery' | 'level' | 'null'>('null');
  const [groupsFilter, setGroupsFilter] = useState<string[]>([]);
  const [usersFilter, setUsersFilter] = useState<string[]>([]);
  const [startDate, setStartDate] = useState(() => {
    const savedDate = sessionStorage.getItem('startCalibFilterDate');
    return savedDate ? moment(savedDate) : moment().subtract(2, 'month').startOf('day');
  });
  useEffect(() => {
    sessionStorage.setItem('startCalibFilterDate', startDate.toISOString());
  }, [startDate]);

  const { loading, calibrations, refreshCalibrations } = useCalibrations(
    searchText,
    usersFilter,
    startDate,
    typeFilter,
    groupsFilter
  );

  // https://react-data-table-component.netlify.app/?path=/docs/selectable-manage-selections--docs
  const [clearSelectionToggle, setClearSelectionToggle] = useState(false);
  const clearSelectedRows = () => {
    setClearSelectionToggle(true);
    setTimeout(() => {
      // Need to reset the toggle for the next time
      setClearSelectionToggle(false);
    }, 0);
  };

  const onSuccess = () => {
    refreshCalibrations();
    clearSelectedRows();
  };

  const columns: IDataTableColumn<ICalibration>[] = useMemo(
    () => [
      {
        selector: 'device_name',
        name: t('silo'),
        sortable: true,
        grow: 1,
        format: (row: ICalibration) => (
          <Box fontWeight="fontWeightBold">
            <Box key={row.idDevice}>{row.device_name}</Box>
          </Box>
        )
      },
      {
        selector: 'poi_name',
        name: t('farm_name_silo_industry'),
        sortable: true,
        grow: 1
      },
      {
        selector: 'date',
        name: t('calibration.date'),
        grow: 1,
        sortable: true,
        format: (row: ICalibration) => formatDate(row.calibration_date, APP_TIMESTAMP_FORMAT)
      },
      {
        selector: 'tonnage',
        name: t('string_workspace_filling_unit', { value: t('orders.tonnage') }),
        grow: 1,
        center: true,
        sortable: true,
        hide: 'md',
        format: (row: ICalibration) => t('number_workspace_filling_unit', { value: row.quantity }),
        sortFunction: (a, b) => {
          if (isNaN(a.quantity) || isNaN(b.quantity)) return 0; // Gérer les valeurs non numériques
          return a.quantity - b.quantity;
        }
      },
      {
        selector: 'type',
        name: t('resourcetype'),
        grow: 1,
        center: true,
        sortable: true,
        hide: 'md',
        format: (row: ICalibration) => t(`orders.${row.type}`)
      },
      {
        selector: 'created_at',
        name: t('created_at'),
        grow: 1,
        center: true,
        sortable: true,
        hide: 'md',
        format: (row: ICalibration) => {
          return formatDate(row.created_at, APP_TIMESTAMP_FORMAT);
        }
      },
      {
        selector: 'by',
        name: t('user'),
        grow: 1,
        center: true,
        sortable: true,
        hide: 'md',
        format: (calibration: ICalibration) => {
          const isExport = ['external', 'export'].includes(calibration.source);
          return (
            <Box display="flex">
              <UserAvatar
                user={
                  isExport
                    ? { first_name: capitalize(calibration.source) }
                    : calibration?.created_by
                }
                key={calibration.idCalibration}
                withTooltip
              />
            </Box>
          );
        }
      }
    ],
    [t]
  );

  const contextActions = selectedRows.length === 1 && (
    <Box style={{ gap: 8, display: 'flex' }}>
      <PrivateComponent resource="Calibrations" operation={['UPDATE']}>
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() =>
            selectedRows[0].type === 'level'
              ? setOpenLevelEditionDialog(true)
              : setOpenDeliveryEditionDialog(true)
          }
        >
          <Edit />
        </Button>
      </PrivateComponent>

      <PrivateComponent resource="Calibrations" operation={['DELETE']}>
        <ConfirmDelete
          onConfirm={() =>
            selectedRows[0].type === 'level'
              ? deleteLevel(selectedRows[0].idCalibration).then(_ => {
                  onSuccess();
                  dispatch(successNotification(t('calibration.level_deleted')));
                })
              : deleteDelivery(selectedRows[0].idCalibration).then(_ => {
                  onSuccess();
                  dispatch(successNotification(t('calibration.delivery_deleted')));
                })
          }
          objectToReturn={selectedRows}
          size="small"
        />
      </PrivateComponent>

      <DeliveryDialog
        onSuccess={onSuccess}
        calibration={selectedRows[0]}
        open={openDeliveryEditionDialog}
        handleClose={() => setOpenDeliveryEditionDialog(false)}
      />

      <CalibrateDialog
        calibration={selectedRows[0]}
        device={
          devices.find(device => device.device_reference === selectedRows[0].idDevice) ||
          ({} as IDevice)
        }
        open={openLevelEditionDialog}
        handleClose={() => setOpenLevelEditionDialog(false)}
        onSuccess={onSuccess}
      />
    </Box>
  );

  const actions = (
    <PrivateComponent resource="Calibrations" operation={['CREATE']}>
      <Button
        style={{ maxHeight: 32 }}
        color="primary"
        variant="contained"
        component={Link}
        to={newCalibrationsDeliveryTo}
      >
        <LocalShipping />
      </Button>
      <Button
        style={{ maxHeight: 32 }}
        color="primary"
        variant="contained"
        component={Link}
        to={newCalibrationsLevelTo}
      >
        <CalibrateIcon fill="white" />
      </Button>
    </PrivateComponent>
  );

  const header = (
    <Box display="flex" flexDirection="row" justifyContent="start">
      {t('calibration.title_short')}
      <TextField
        className={classes.searchField}
        placeholder={t('search')}
        autoFocus
        onChange={e => setSearchText(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="disabled" />
            </InputAdornment>
          )
        }}
      />
      <CalibrationTypeFilter
        type={typeFilter}
        onChange={(event: React.ChangeEvent<any>) => {
          setTypeFilter(event.target.value);
        }}
      />
      <GroupFilter
        onChange={selection => {
          const filter = selection.map(item => item.value);
          setGroupsFilter(filter);
        }}
      />
      <DatePicker
        // label={t('from')}
        disableToolbar
        value={startDate}
        onChange={(value: any) => setStartDate(value)}
        format={APP_LOCAL_DATE_FORMAT}
        disableFuture
        margin="none"
        variant="inline"
        style={{ marginBottom: 8, width: 120 }}
      />
      <UserFilter
        onChange={selection => {
          const filter = selection.map(item => item.value);
          setUsersFilter(filter);
        }}
      />
      {/* <SourceFilter onChange={onSourceChange} /> */}
    </Box>
  );

  return (
    <>
      <Box p={1}>
        <DataTable
          title={header}
          actions={actions}
          contextActions={contextActions}
          columns={columns}
          data={calibrations}
          selectableRows={useIsAuthorised('Calibrations', ['DELETE', 'UPDATE'])}
          defaultSortField="date"
          defaultSortAsc={false}
          progressPending={loading}
          // expandOnRowClicked
          expandableRowsHideExpander
          onSelectedRowsChange={state => setSelectedRows(state.selectedRows)}
          clearSelectedRows={clearSelectionToggle}
          pagination
          paginationRowsPerPageOptions={[100, 200]}
          paginationPerPage={100}
        />
      </Box>
    </>
  );
};

function useCalibrations(
  searchText: string,
  usersFilter: string[],
  startDate: moment.Moment,
  typeFilter: string,
  groupsFilter: string[]
): {
  loading: boolean;
  calibrations: ICalibration[];
  // Force refresh of calibrations
  refreshCalibrations: () => void;
} {
  const [calibrations, setCalibrations] = useState<ICalibration[]>([]);
  const [loading, setLoading] = useState(true);
  const abortController = useRef<AbortController>();

  const filteredCalibrations = useMemo(
    () =>
      calibrations.filter(
        calib => !searchText || calib.device_name.toLowerCase().includes(searchText.toLowerCase())
      ),
    [calibrations, searchText]
  );

  const abortPending = () => {
    abortController.current?.abort();
    abortController.current = undefined;
  };

  const refreshCalibrations = useCallback(async () => {
    // Abort any previous request
    abortPending();

    const thisController = new AbortController();
    abortController.current = thisController;
    setLoading(true);

    const params = getCalibrationQueryParams(usersFilter, startDate, typeFilter, groupsFilter);
    try {
      const data = await fetchCalibrations(params, thisController.signal);

      if (!thisController.signal.aborted) {
        setCalibrations(data);
      }
    } catch (e) {
      if (axios.isCancel(e)) {
        console.log('Request canceled', e.message);
      } else {
        console.error('Error fetching calibrations:', e);
      }
    } finally {
      if (!thisController.signal.aborted) {
        setLoading(false);
      }
    }
  }, [usersFilter, startDate, typeFilter, groupsFilter]);

  // Auto-fetch calibrations based on current filters
  useEffect(() => {
    refreshCalibrations();
    return () => {
      abortPending();
    };
  }, [refreshCalibrations]);

  return { loading, calibrations: filteredCalibrations, refreshCalibrations };
}

async function fetchCalibrations(params: Record<string, string>, signal: AbortSignal) {
  const response: AxiosResponse<ICalibration[]> = await axios.get(
    `${getEnvApiUrl()}/v2/calibrations`,
    { params, signal }
  );

  return response.data.map(c => ({
    ...c,
    calibration_date: convertDateFromServer(c.calibration_date as string)
  }));
}

function getCalibrationQueryParams(
  usersFilter: string[],
  startDate: moment.Moment,
  typeFilter: string,
  groupsFilter: string[]
) {
  const hasExternal = usersFilter.includes('external');
  const createdByUserIds = usersFilter.filter(id => id !== 'external');

  const params: Record<string, string> = {
    source: [
      !(hasExternal && createdByUserIds.length === 0) && 'manual,delivery',
      (hasExternal || createdByUserIds.length === 0) && 'external,export'
    ]
      .filter(isTruthy)
      .join(','),
    start_date: startDate.toISOString(),
    excludeNanolike: 'true'
  };

  if (typeFilter !== 'null') {
    params.type = typeFilter;
  }

  if (groupsFilter.length > 0) {
    params.group_ids = groupsFilter.join(',');
  }

  if (createdByUserIds.length > 0) {
    params.created_by_id = createdByUserIds.join(',');
  }

  return params;
}

async function deleteDelivery(id: string) {
  const apiUrl = getEnvApiUrl();
  await axios.delete(`${apiUrl}/internal/calibration-delivery/${id}`);
}

async function deleteLevel(id: string) {
  const apiUrl = getEnvApiUrl();
  await axios.delete(`${apiUrl}/internal/calibration-level/${id}`);
}

export default Calibrations;
